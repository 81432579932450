.tableRow .tableRowItem, .tableRow .tableRowItem.check {
  vertical-align: middle;
  align-self: center;
}
.tableRow .tableRowItem.maxWidth .columnContent {
  width: max-content;
}
.tableRow .tableRowItem.maxWidth span {
  display: flex;
  width: max-content;
  gap: 4px;
}
.tableRow .tableRowItem.fixedWidth .columnContent, .tableRow .tableRowItem.fixedWidth .columnContent span{
  max-width: 200px;
  width: 200px;
  display: inline-flex;
  flex-shrink: 2;
}
.tableRowItem span, .tableRowItem svg, .tableRowItem span span {
  vertical-align: top;
  width: max-content;
}
.tableRow .tableRowItem:nth-of-type(3) svg {
  padding-right: 8px;
}
.tableRow .tableRowItem.center, .tableRow .tableRowItem.menu {
  text-align: center;
}
.tableRow .tableRowItem .status {
  display: inline-flex;
  gap: 0;
}
.tableRow .tableRowItem .columnContent {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: none;
  width: inherit;
}
.tableRow .tableRowItem.check .columnContent {
  padding: 16px 16px 16px 16px;
}
.tableRow .tableRowItem .columnContent span:has(.activeUser) {
  display: inline-flex;
  gap: 4px;
}
.tableRow.hover {
  background-color: var(--gray3);
}
.menu {
  position: relative;
}
.topLeft {
  left: 50%;
  top: 48px;
}
.warning {
  color:var(--red1)
}
.undefined {
  color: var(--gray2)
}
.activeUser {
  color: var(--blue2);
}
.normal{
  color: var(--black)
}