.activeUser {
  color: var(--blue2);
}
.tableRow {
  display: table-row;
}
.tableRow .tableRowItem.tableItem, .tableRow .tableRowItem.tableItem.check {
  max-width: max-content;
  min-width: max-content;
  display: table-cell;
  height: 48px;
  padding: 10px 24px 12px 24px;
}
.tableRow .tableRowItem.tableItem.check {
  padding: 16px;
}
.tableRowItem span, .tableRowItem svg, .tableRowItem span span {
  vertical-align: top;
}
.tableRow .tableRowItem:nth-of-type(3) svg {
  padding-right: 8px;
}

.tableRow .tableRowItem:nth-of-type(4) {
  padding: 16px 24px 12px;
}
.tableRow .tableRowItem:nth-of-type(5), .tableRow .tableRowItem:nth-of-type(4) {
  text-align: center;
}
.tableRow .tableRowItem.status div {
  display: inline-block;
}
.menu {
  position: relative;
}
.topLeft {
  left: 50%;
  top: 48px;
}