div.searchBar.searchBarInput {
    --mainWidth: 400px;
    margin-top: 44px;
}

div.list {
    width: 400px;
    margin: 0;
    align-items: normal;
    position: absolute;
    overflow-y: auto;
}

.open>div {
    border-radius: 8px 8px 0 0;
}

.dropdownButton {
    margin: 0;
}