.wrapper {
    display: flex;
}
.wrapper > div:first-of-type {
    position: absolute;
}
.overlay {
    z-index: 3;
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
}
.settingSubMenu {
    position: absolute;
    right: 0;
    z-index: 4;
}
