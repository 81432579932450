.title {
  width: 480px;
}
.title > div:first-child, .title > div:first-child span {
  max-width: 95%;
}

.title > div:first-child span {
  overflow: visible;
  white-space: break-spaces;
}