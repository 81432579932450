.mainContainer {
    max-height: 100vh;
    min-width: calc(100vw - 60px);
    overflow: hidden;
    display: grid;
    margin-left: 64px;
    grid-template-columns: 280px calc(100vw - 344px);
    grid-template-rows: 160px calc(100vh - 160px);
    grid-template-areas:
        "submenu banner"
        "submenu content"
}

.submenu {
    grid-area: submenu;
}

.banner {
    grid-area: banner;
}

.content {
    grid-area: content;
    padding: 64px;
    overflow-y: scroll;
}

.profilePicker {
    margin-bottom: 40px;
}

.spacer {
    margin-bottom: 48px;
}