.page {
    max-height: 100vh;
    min-width: calc(100vw - 60px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 64px 280px calc(100vw - 344px);
    grid-template-rows: 160px calc(100vh - 160px);
    grid-template-areas:
        "sidebar submenu banner"
        "sidebar submenu content"
}

.sidebar {
    grid-area: sidebar;
}

.submenu {
    grid-area: submenu;
}

.resellerActionBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.settingBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
}

.banner {
    grid-area: banner;
}

.content {
    grid-area: content;
    padding: 64px;
    overflow-y: scroll;
}

.textContainer {
    margin-bottom: 24px;
}

.settingContainer {

    margin-bottom: 64px;
}

.buttonContainer {
    display: flex;
    align-items: flex-end;
}

.buttonContainerSave {
    display: flex;
    align-items: flex-end;
    margin-left: -40px;
}

.recommendedText {

    color: #666;
}

.recommendedTextContainer {
    display: flex;
}

.resellerInput label {
    width: max-content;
}

.settingBlock .bigInputContainer {
    --mainWidth: 320px;

}
.passwordChangeLink{
    color: var(--blue2);
    cursor: pointer;
}
.proef {

    background-color: #282c34;
    height: 100vh;
}