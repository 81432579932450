.page {
    max-height: 100vh;
    min-width: calc(100vw - 60px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 64px 280px calc(100vw - 344px);
    grid-template-rows: 160px calc(100vh - 160px);
    grid-template-areas:
        "sidebar submenu banner"
        "sidebar submenu content"
}

.sidebar {
    grid-area: sidebar;
}

.submenu {
    grid-area: submenu;
}

.banner {
    grid-area: banner;
}

.inputGroup {
    width: 100%;
}

.content {
    grid-area: content;
    padding: 64px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    gap: 64px;
}

.inputContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 48px;
    max-width: 700px;
}
.havePopup {
    position: relative;
}
.havePopup .popup {
    top: -50%;
    left: 100%;
    width: max-content;
}
.backgroundImage {
    background: url("/public/The_board_meeting_KF_1774_LR.jpg");
    background-position-y: 50%;
}