.sidebar {
    grid-area: sidebar;
}

.header {
    grid-area: header;
    background: url("https://www.dusol.nl/wp-content/uploads/TQ01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 12%;
    width: 100%;
}

.overlay {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    padding: 96px 64px;
    height: -webkit-fill-available;
}

.body {
    grid-area: body;
    overflow-y: scroll;
    height: 100%;
}

.mainContainer {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: grid;
    overflow: hidden;
    grid-template-areas:
        "sidebar body";
    grid-template-columns: 64px 1fr;
}

.dayPart {
    font-family: "quatro";
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
}

.userName {
    font-family: "quatro";
    font-size: 48px;
    font-weight: 400;
    color: white;
}

.titleSubTitle {
    margin-bottom: 24px;
}

.quickAccess {
    padding: 64px;
}

.apps {
    padding: 24px 64px 64px 64px;
}