.background {
    width: 100%;
    min-height: 100%;
    position: absolute;
    object-fit: none;
}

@media (min-width: 2000px) {
    .background {
        object-fit: contain;
    }
}

.overlay {
    position: absolute;
    right: 0;
    max-width: 480px;
    height: 100%;
    width: 33%;
    backdrop-filter: blur(40px);
    background: rgba(0, 159, 218, 0.50);
}

@media (max-width: 1060px) {
    .overlay {
        max-width: 600px;
        width: 50%;
    }
}

@media (max-width: 750px) {
    .overlay {
        width: 100%;
        max-width: 100%
    }
}

.content {
    padding: 96px 64px 96px 64px;
}

.logo {
    width: 111px;
    height: 30px;
}

.title {
    font-size: 48px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
}

.body {
    margin: 48px 0 48px 0;
    color: white;
}

.bodyTitle {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
}

.container {
    position: relative;
    min-width: 100%;
    min-height: 100%;
}