.container.open,
.container.closed {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* grid-template-rows: 26px 32px repeat(auto-fill, 48px); */
  gap: 8px;
  padding: 40px 8px 20px 8px;
  overflow: hidden;
}

.important.container.open {
  width: 280px;
}

.important.container.closed {
  width: 64px;
}

.closed .logoContainer {
  padding: 0px 10px 48px 10px;
}

.open .logoContainer {
  padding: 0px 22px 48px 23px;
}

.open .upperContainer,
.open .lowerContainer {
  padding: 0 12px;
}

.positions {
  text-decoration: none;
}

.closed .userContainer {
  width: 48px;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  /* gap: 48px; */
  color: white;
}

.lowerContainer {
  color: white;
}

.firstLinksContainer {
  padding-bottom: 66px;
}

.secondLinksContainer {
  color: white;
}

.active {
  padding: 17px !important;
}

.closed .positions:not(.bold) button {
  padding: 14px;
}

.closed .positions button {
  width: 48px;
  padding: 17px;
}

.positionHome {
  grid-row-start: 3;
}

.positionOrigin {
  grid-row-start: 4
}

.positionEcho {
  grid-row-start: 5;
}

.positionSphere {
  grid-row-start: 7
}

.positionSLA {
  grid-row-start: 8
}

.positionAccountMan {
  grid-row-start: 9
}

.positionSupport {
  grid-row-start: 10
}

.positionUser {
  grid-row-end: -1;
}