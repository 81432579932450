.tableHeader .tableHeaderItem {
  padding: 16px;
  width: inherit !important;
}

.tableHeader .tableHeaderItem:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.tableHeader .tableHeaderItem:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.tableHeader .tableHeaderItem.headerCheckBox {
  padding: 14px 16px 12px 16px;
  width: 48px;
  min-width: 48px;
}
.tableHeader .tableHeaderItem.headerCheckBox span {
  display:inline-block;
  width: 16px;
}
.center {
  text-align: center;
}
.tableHeader .tableHeaderItem.maxWidth {
  width: auto;
}
.tableHeader .tableHeaderItem.fixedWidth {
  width: 200px;
}