.container {
    width: 100%;
    height: 440px;
    position: relative;
    overflow: hidden;
}

.background {
    height: 400px;
    width: 100%;
    background: #F0F1F3;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.banner {
    min-height: 400px;
    min-width: 688px;
    max-width: 688px;
    position: relative;
    bottom: -40px;
    right: 40px;
    overflow: hidden;
    display: flex;
}

.body {
    box-sizing: border-box;
    position: relative;
    max-width: 520px;
    height: 440px;
    font-family: "quatro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 64px;
}

.description {
    margin-top: 24px;
    margin-bottom: 42px;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    width: 400px;
    height: 48px;
    display: flex;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.actionButtons {
    gap: 8px;
    display: flex;
    flex-direction: row;
}
/* @media (max-width:1250px) {
    .container {
        position: relative;
    }
    .background, .container {
        height: 100%;
    }
    .body {
        height: 100%;
        width: 100%;
        max-width: 100%;
    }
    .content {
        flex-direction: column-reverse;
   }
   .banner {
    width: 90%;
    max-width: 100%;
    left: 20px;
    top: -20px;
 
   }
   .image {
    position: absolute;
    padding: 40px;
    object-fit: cover;
   }
} */
