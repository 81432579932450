.customWidth {
  --contextContainerWidth: 120px;
}
.tempContext.bottomLeft {
  border-radius: 16px 16px 16px 0px;
}
.tempContext.topRight {
  border-radius: 16px 0px 16px 16px;
}
.tempContext.topLeft {
  border-radius: 0px 16px 16px 16px;
}
.tempContext.bottomRight {
  border-radius: 16px 16px 0px 16px;
}