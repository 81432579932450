.mainContainer {
  max-height: 100vh;
  min-width: calc(100vw - 60px);
  overflow: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 64px 280px calc(100vw - 344px);
  grid-template-rows: 160px calc(100vh - 160px);
  grid-template-areas:
    "sidebar submenu banner"
    "sidebar submenu content"
}

.sidebar {
  grid-area: sidebar;
}

.submenu {
  grid-area: submenu;
}

.banner {
  grid-area: banner;
}

.content {
  grid-area: content;
  padding: 64px;
  overflow-y: scroll;
}


.contentHeaderWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.filterWrapper {
  position: relative;
}

.accountsFilter {
  height: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.profilePicker {
  margin-bottom: 40px;
}

.accountsFilter .text.reset,
.accountsFilter .reset.icon {
  color: var(--red1)
}

.overlay {
  z-index: 3;
  background: rgba(0, 0, 0, 0.40);
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
}

.settingSubMenu {
  position: absolute;
  right: 0;
  z-index: 4;
}

.wrapper {
  display: flex;
}
.titleContainer .infoText {
  width: 100%;
}