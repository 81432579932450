.table {
  --tableFullWidth: 100%;
  --tableColumnWidth1: max-content;
  --tableColumnWidth: calc((var(--tableFullWidth) / 3) - 48px);
  --tableColumnWidth2: max-content;
  --tableColumnWidth3: max-content;
  --tableColumnWidth4: max-content;
  --tableColumnWidth5: max-content;
  display: table;
  width: var(--tableFullWidth);
  table-layout: auto;
}
.table.minWidth {
  min-width: 774px;
  overflow-x: scroll;
}
.tableHeader {
  display: table-row;
  width: inherit;
}
.tableBody {
  display: table-row-group;
}
.tableRow {
  width: inherit;
}