
.page {
    max-height: 100vh;
    min-width: calc(100vw - 60px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 64px 280px calc(100vw - 344px);
    grid-template-rows: 160px calc(100vh - 160px);
    grid-template-areas:
        "sidebar submenu banner"
        "sidebar submenu content"
}

.sidebar {
    grid-area: sidebar;
}

.submenu {
    grid-area: submenu;

}

.settingBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;

}
.settingContainer{

    margin-bottom: 64px;
}

.banner {
    grid-area: banner;
}

.content {
    grid-area: content;
    padding: 64px;
    overflow-y: scroll;

}

.textContainer {
    margin-bottom: 24px;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 48px;
}

.connectionicon {
    width: 320px;
    height: 200px;
    background-color: #F0F1F3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px ;
}

.connectionicon.icon {
    font-size: 5em; /* aanpassen naar gewenste grootte */
    color: #000; /* aanpassen naar gewenste kleur */
}

.profilePicker{

    margin-bottom: 40px;
}

