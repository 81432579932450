.container {
  padding: 12px 40px;
  display: flex;
  flex-direction: column;
  gap: 8px
}
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.empty {
  width: 16px;
}
div.listItem.paragraph {
  padding: 0;
  min-height: min-content;
}