.wrapper {
    display: flex;
}

.mainContainer {
    max-height: 100vh;
    min-width: calc(100vw - 60px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 64px 280px calc(100vw - 344px);
    grid-template-rows: 160px calc(100vh - 160px);
    grid-template-areas:
        "sidebar submenu banner"
        "sidebar submenu content"
}

.sidebar {
    grid-area: sidebar;
}

.submenu {
    grid-area: submenu;
}

.banner {
    grid-area: banner;
}

.content {
    grid-area: content;
    padding: 64px;
    overflow-y: scroll;
}

.profilePicker {
    margin-bottom: 40px;
}

.overlay {
    z-index: 3;
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
}

.settingSubMenu {
    position: absolute;
    right: 0;
    z-index: 4;
}

.spacer {
    margin-bottom: 48px;
}