.inputWrapper {
  width: 200px;
  height: 40px;
  background-color: #F0F1F3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  color: #666666;
  font-family: 'Quatro';
}

.input {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 100%;
}

input[type="color"] {
  opacity: 1;
  width: 160px;
  height: 160px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001A;
  padding: 0;
  margin: -30px 0 0 -30px;
}

.isDisabled,
.isDisabled input {
  cursor: no-drop;
  color: #ccc;
}