.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-areas:
      "sidebar body";
  grid-template-columns: 64px 1fr;

}
.mainMenu {
  grid-area: sidebar;
}
.page {
  grid-area: body;
  display:flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 48px;
}
.titleContainer {
  width: 470px;
  height: 100px;
  display: flex ;
  flex-direction: row;
  gap: 24px;
}
.ghost {
  color: var(--blue2, #009FDA);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 96px */
  text-transform: uppercase;
}
.title {
  font-family: "quatro";
  font-size: 48px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height:  48px;
  color: var(--blue2);
}
.text {
  text-align: center;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}