div.tableHeader .tableHeaderItem {
  display: table-cell;
  padding: 16px;
}
.tableHeader .tableHeaderItem:nth-of-type(1) {
  width: 48px;
}
.tableHeader .tableHeaderItem:nth-of-type(2) {
  width: var(--tableColumnWidth);
  --tableColumnWidth2: max-content;
  min-width: var(--tableColumnWidth2);
}
.tableHeader .tableHeaderItem:nth-of-type(3) {
  width: var(--tableColumnWidth);
  --tableColumnWidth3: max-content;
  min-width: var(--tableColumnWidth3);
}
.tableHeader .tableHeaderItem:nth-of-type(4) {
  width: var(--tableColumnWidth);
  --tableColumnWidth4: max-content;
  min-width: var(--tableColumnWidth4);
}
.tableHeader .tableHeaderItem:nth-of-type(5) {
  width: var(--tableColumnWidth);
  --tableColumnWidth5: max-content;
  min-width: var(--tableColumnWidth5);
}
.tableHeader .tableHeaderItem.headerCheckBox {
  padding: 16px;
  width: 16px;
}
.tableHeader .tableHeaderItem.headerCheckBox span {
  display:inline-block;
  width: 16px;
}
.center {
  text-align: center;
}