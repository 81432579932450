.title {
    color: #000;
    font-family: 'Quatro';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 16px */
    text-transform: uppercase;
    margin-bottom: 8px;
}

.subtitle {
    color: var(--Black, #000);
    /* Body */
    font-family: 'Quatro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 16px */
}

.container {
    display: flex;
    flex-direction: column;
}