.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
}

.settingContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 24px;
}

.input {
    align-items: start;
    justify-content: start;
}

.imageListContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}