.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template-areas:
      "sidebar body";
  grid-template-columns: 64px 1fr;

}
.mainMenu {
  grid-area: sidebar;
}
.page {
  grid-area: body;
  display:flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.loading {
  color: var(--Gray-1, #666);
  text-align: center;
  font-style: normal;
  font-size: 24px;
  font-weight: 900;
  line-height:  39.6px;
  letter-spacing: 0.72px;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}