.background {
    width: 100%;
    min-height: 100%;
    position: absolute;
}

.logo {
    margin: 64px;
    z-index: 10;
    height: 50px;
    width: 218px;
}

.overlay {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    background: rgba(0, 159, 218, 0.50);
    backdrop-filter: blur(24px);
}

.container {
    position: relative;
    min-width: 100%;
    min-height: 100%;
}