* {
  font-family: "Quatro", "Verdana", sans-serif;
}
.footer.footerStyling {
  position: absolute;
  bottom: 0;
  width: calc(100vw - 72px);
  left: 64px;
  z-index: 2;
  overflow: hidden;
}

.contextMenu {
  bottom: 38px;
  right: 66px;
  --contextContainerWidth: 172px !important;
}

.mainContainer {
  overflow: hidden;
  height: 100vh;
}