.table {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  min-width: 774px;
}
.tableHeader, .tableRow { 
  display: grid; 
  grid-auto-flow: column dense; 
}
.tableRow {
  border-bottom: 1px solid var(--gray3);
  min-height: 48px;
}
